// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  ArrayInput,
  Create,
  CreateBase,
  Edit,
  EditBase,
  List,
  ListBase,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useCreate,
  useDeleteMany,
  useEditContext,
  useListContext,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar, HazPerms } from '../component/customComponents';
import { MyEdit } from '../component/MyEdit';
import MyTable from '../component/MyTable';
import { copyToClipboard } from '../utils/utils';

import './styles.css';

const headers = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'updatedAt',
    header: 'Last Updated',
  },
  {
    key: 'list_count',
    header: 'List Members',
  },
];

export const Table = () => {
  const { permissions, isLoading } = usePermissions();
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [deleteMany, { error: deleteError }] = useDeleteMany();
  const notify = useNotify();
  const [doDelete, setDoDelete] = React.useState(false);
  const [doneDelete, setDoneDelete] = React.useState(false);

  React.useEffect(() => {
    async function deleteRecords() {
      if (doDelete) {
        await deleteMany('domainlist', { ids: rowsToDelete });
        setDoneDelete(true);
      }
      setDoDelete(false);
    }
    deleteRecords();
  }, [doDelete]);

  React.useEffect(() => {
    async function foo() {
      if (deleteError) {
        notify(`failed to delete record(s): ${deleteError}`, { type: 'error' });
      } else {
        notify('deleted record(s)', { type: 'success' });
      }
    }
    if (doneDelete) foo();
  }, [deleteError, doneDelete]);

  if (isPending || !data || isLoading) return null;

  const batchDeleteAction = async () => {
    setDoDelete(true);
    setConfirmationOpen(false);
  };

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  data.forEach((result, idx, arr) => {
    // conditionally add edit link
    if (HazPerms(permissions, 'policies', 'edit')) {
      arr[idx].edit = <MyEdit url={`/domainlist/${result.id}`} key={`edit-${idx}`} />;
      if (!headers.some((e) => e.key === 'edit')) headers.push({ key: 'edit', header: 'Edit' });
    }
    arr[idx].list_count = result.list?.length || 0;
    arr[idx].updatedAt = <Typography variant="body2"> {new Date(arr[idx].updated_at).toLocaleString()}</Typography>;
  });
  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title={'Are you sure you want to delete these Domainlists?'}
        batchActionClick={batchDeleteAction}
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable data={data} headers={headers} showActions={true} zebra={false} addRow={() => redirect('create', 'domainlist')} bulkAction={confirmBulkDelete} />
    </>
  );
};

export const DomainListFrom = ({ props, isEdit = false }) => {
  const notify = useNotify();
  const [create] = useCreate();
  const [update] = useUpdate();
  const redirect = useRedirect();

  const { isLoading, isFetching } = useEditContext();
  if (isLoading || isFetching) {
    return (
      <div className="container">
        <div className="loading">
          <div className="loading__letter">L</div>
          <div className="loading__letter">o</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">d</div>
          <div className="loading__letter">i</div>
          <div className="loading__letter">n</div>
          <div className="loading__letter">g</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
        </div>
      </div>
    );
  }

  const handleCopy = () => {
    const success = copyToClipboard(document.getElementById('domainID').innerHTML);
    if (success) {
      notify('Domain list ID copied to clipboard', { type: 'success', autoHideDuration: 5000 });
    }
  };

  const postSave = (data) => {
    // remove any empty values from the list
    data.list = data.list.filter((r) => r !== '');
    isEdit ? update('domainlist', { id: data.id, data: data }) : create('domainlist', { data });
    redirect('/domainlist');
  };

  return (
    <SimpleForm {...props} toolbar={<CustomToolbar redirectPath={'/domainlist'} />} onSubmit={postSave}>
      <TextInput fullWidth source="name" />
      <TextInput fullWidth source="description" />
      <div className="myDiv" onClick={isEdit ? handleCopy : null}>
        <Typography variant="caption" display={'block'}>
          List ID
        </Typography>
        <TextField disabled source="id" id="domainID" />
      </div>
      <ArrayInput label="list" source="list">
        <SimpleFormIterator fullWidth>
          <TextInput fullWidth></TextInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export const DomainListCreate = ({ props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect('/domainlist');
    refresh();
    notify(`Domain list created`, { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/domainlist'} />
      <Create {...props} mutationOptions={{ onSuccess }}>
        <DomainListFrom
          defaultValues={{
            name: '',
            description: '',
            list: [],
          }}
        />
      </Create>
    </CreateBase>
  );
};

export const DomainListEdit = ({ props }) => (
  <EditBase>
    <MyBreadcrumbs path={'/domainlist'} />
    <Edit {...props} mutationMode="pessimistic">
      <DomainListFrom {...props} isEdit={true} />
    </Edit>
  </EditBase>
);

export const DomainListList = () => {
  return (
    <ListBase>
      <MyBreadcrumbs path={'/domainlist'} />
      <List perPage={5} pagination={false} actions={false}>
        <Table />
      </List>
    </ListBase>
  );
};

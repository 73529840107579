// © Copyright IBM Corp. 2022, 2024

import React from 'react';

import { JsonInput } from 'react-admin-json-view';

const JsonEditor = (props) => {
  const { source } = props;
  // const {
  //     field,
  //     fieldState: {isTouched, invalid, error},
  //     formState: {isSubmitted},
  //     isRequired
  // } = useInput({
  //     // Pass the event handlers to the hook but not the component as the field property already has them.
  //     // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
  //     onChange,
  //     onBlur,
  //     ...props,
  // });

  // const [isEditing, setIsEditing] = useState(false);

  // const handleToggleEdit = (e) => {
  //   // e.stopPropagation();
  //   e.preventDefault();
  //   setIsEditing(!isEditing);
  // };

  return (
    <>
      {/* commented out the raw json input as it doesn't handle invalid json gracefully
        having users inout via json widget has a better UX at this point in time
     */}
      {/*<div onClick={handleToggleEdit}></div>*/}
      {/* <button onClick={handleToggleEdit}>
        {isEditing ? 'View JSON' : 'Edit Raw JSON'}
      </button> */}
      {/* {isEditing ? (
        <ReactAdminEditor
          language="json"
          source={source}
          // <TextInput
          //     source="properties"
          //     fullWidth={true}
          //     multiline={true}
          format={(inputValue) => JSON.stringify(inputValue, null, 2)}
          parse={(inputValue) => JSON.parse(inputValue)}
        />
      ) : ( */}
      <JsonInput
        source={source}
        //validate={[required()]}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: true,
          displayDataTypes: false,
        }}
      />
      {/* )} */}
    </>
  );
};

export default JsonEditor;

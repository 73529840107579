// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Link, useGetRecordRepresentation, useRecordContext } from 'react-admin';

import { Breadcrumbs, Typography } from '@mui/material';

export const MyBreadcrumbs = ({ record, path }) => {
  const crumb = path.split('/').slice(1, 2);
  const currentUrl = window.location.href;
  const rec = record ? record : useRecordContext();
  const getRecordRepresentation = useGetRecordRepresentation(crumb);

  var p = '';
  return (
    <div role="presentation" style={{ marginBottom: '5px' }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/">
          Home
        </Link>

        {path
          ?.split('/')
          .slice(1)
          .map((i, k) => {
            p = p + '/' + i;
            return (
              <Link underline="hover" color="inherit" to={p} key={k}>
                {i}
              </Link>
            );
          })}

        {rec ? (
          <Typography color="text.primary">{record ? rec : getRecordRepresentation(rec)}</Typography>
        ) : (
          currentUrl.endsWith('create') && <Typography color="text.primary">create</Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};

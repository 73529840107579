// © Copyright IBM Corp. 2022, 2024

// in src/MyLoginPage.js
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useNotify } from 'react-admin';
import { FaGithub, FaOpenid } from 'react-icons/fa';
import { SiAuth0 } from 'react-icons/si';

import { ArrowRight } from '@carbon/icons-react';
import {
  Button,
  Checkbox,
  Column,
  Form,
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderName,
  InlineNotification,
  Link,
  Loading,
  PasswordInput,
  SkipToContent,
  Stack,
  TextInput,
  Theme,
} from '@carbon/react';

import { Box, Typography } from '@material-ui/core';
import Grid from '@mui/material/Unstable_Grid2';

import config from '../config';

const componentsMap = new Map([
  ['github', FaGithub],
  ['Auth0', SiAuth0],
  ['oidc', FaOpenid],
]);

const LoginResolver = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [step2, setStep2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [org, setOrg] = useState('');
  const [invalidOrg, setInvalidOrg] = useState(false);
  const [loginURL, setLoginURL] = useState('');
  const notify = useNotify();

  useEffect(() => {
    async function tryLogin() {
      if (loginURL) {
        setLoading(true);
        let next = '';
        if (window.location.toString().includes('next=')) {
          next = window.location.toString().split('next=')[1];
        }
        window.location.href = loginURL + `?next=${window.location.origin}/${next}`;
      }
    }
    tryLogin();
  }, [loginURL]);

  const getProviders = () => {
    setLoading('active');
    fetch(`${config.apiUrl}/v1/auth/begin/${org}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 404) {
          setProviders([]);
          throw { orgId: 'Provided Org Has No Configured Providers' };
        }
        return res.json();
      })
      .then(
        (result) => {
          setProviders(result.auth_providers);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          for (const [k, v] of Object.entries(error)) {
            notify(`${k}: ${v}`, { type: 'error' });
            if (k === 'orgId') setInvalidOrg('Provided Org Has No Configured Providers');
          }
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = async (e) => {
    console.log('in submit');
    e.preventDefault();
    if (!step2 && email) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setStep2(true);
        setInvalidEmail(false);
      } else {
        console.log('invalid email');
        setInvalidEmail('Invalid email.');
      }
    } else if (email && password) {
      notify('This method of authentication is currently not active. Please use one of the alternative logins below.', { type: 'warning' });
      document.getElementById('org').focus();
      setPassword('');
    } else {
      // do something here, maybe
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.key === 'Enter') getProviders();
  };

  return (
    <>
      {loading && loginURL && <Loading withOverlay={true} />}
      <Grid container spacing={2} style={{ height: '100vh', marginTop: '40px' }}>
        <Grid xs={3} md={3} lg={3}>
          <Stack gap={6}>
            <div style={{ width: '400px', marginLeft: '1em', paddingTop: '20px' }}>
              <Typography variant="h5" gutterBottom>
                Log in with W3
              </Typography>
              {!step2 ? (
                <div className="no-account">
                  Don&apos;t have and account? <Link href="#">Create and IBMid</Link>
                </div>
              ) : (
                <div className="no-account">Logging in as {email}</div>
              )}
              <hr />
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {!step2 && (
                  <TextInput
                    invalid={!!invalidEmail}
                    invalidText={invalidEmail}
                    data-modal-primary-focus
                    labelText="Continue with IBMid"
                    placeholder="some.user@ibm.com"
                    id="username"
                    onChange={(v) => setEmail(v.target.value)}
                    onBlur={(v) => {
                      setEmail(v.target.value);
                      setGeneralError();
                    }}
                  />
                )}
                {!step2 && (
                  <Button className="login" type="button" onClick={submit} disabled={email ? false : true}>
                    Continue
                    <ArrowRight className="arrow" />
                  </Button>
                )}
                {!step2 && <Checkbox id="rememberMe" labelText="Remember Me" />}
                {step2 && (
                  <PasswordInput
                    invalid={!!invalidPassword}
                    invalidText={invalidPassword}
                    placeholder="password"
                    labelText="Password"
                    id="password"
                    onChange={(p) => {
                      setPassword(p.target.value);
                      setInvalidPassword('');
                    }}
                  />
                )}
                {step2 && (
                  <Button className="login" type="submit" onClick={submit} disabled={password ? false : true}>
                    Submit
                    {loading && password && email ? <Loading className={'some-class'} withOverlay={false} small={true} /> : <ArrowRight className="arrow" />}
                  </Button>
                )}
              </Form>
              {generalError && (
                <InlineNotification hideCloseButton aria-label="general login error" kind="error" statusIconDescription="error" title={generalError} />
              )}
            </div>
            <div style={{ marginLeft: '1em' }}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                onKeyDown={onKeyDownHandler}
              >
                <hr />
                <Grid style={{ paddingLeft: '0px' }}>
                  <Column lg={16} md={8} sm={4}>
                    <div className="no-account">Alternative Logins</div>
                  </Column>
                  <Column lg={16} md={8} sm={4}>
                    <div className="no-account">Enter in your Organization ID</div>
                  </Column>
                  <Column lg={4} md={4} sm={4}>
                    <TextInput
                      labelText="Organization ID"
                      placeholder="my-org"
                      id="org"
                      onBlur={(v) => {
                        setOrg(v.target.value);
                        setInvalidOrg('');
                      }}
                      onChange={(v) => {
                        setOrg(v.target.value);
                        setInvalidOrg('');
                      }}
                      invalid={!!invalidOrg}
                      invalidText={invalidOrg}
                    />
                    <Button className="login" style={{ marginBottom: '15px' }} type="button" onClick={getProviders} disabled={!org}>
                      Get Login Providers
                      {loading && org && !loginURL ? <Loading className={'some-class'} withOverlay={false} small={true} /> : <ArrowRight className="arrow" />}
                    </Button>
                  </Column>

                  {Object.values(providers).map((provider) => (
                    <Column lg={16} md={8} sm={4} key={provider.display_name}>
                      <div key={provider.display_name}>
                        <Button
                          onClick={() => {
                            setLoginURL(provider.url);
                          }}
                          renderIcon={componentsMap.get(provider.icon)}
                          style={{ marginBottom: '5px', width: '100%' }}
                        >
                          Sign in with {provider.display_name}
                        </Button>
                      </div>
                    </Column>
                  ))}
                </Grid>
              </Form>
            </div>
          </Stack>
        </Grid>
        <Grid xs={9} md={9} lg={9}>
          <Box component="img" src="/tab-illo.png" alt="background image" width={800} height={800} />
        </Grid>
      </Grid>
    </>
  );
};

const LoginPage = () => {
  return (
    <Theme theme="g10">
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Zero Trust Connect">
              <SkipToContent />

              <HeaderName href="/" prefix="IBM">
                [Zero Trust Connect]
              </HeaderName>
              <HeaderGlobalBar />
            </Header>
            <LoginResolver />
          </>
        )}
      />
    </Theme>
  );
};

export default LoginPage;

// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@carbon/react';

import EditIcon from '@mui/icons-material/Edit';

class MyEdit extends React.Component {
  render() {
    return (
      <Link to={this.props.url}>
        <Button
          kind="ghost"
          as="div"
          style={{ width: '70px', paddingLeft: '0px' }}
          renderIcon={(props) => <EditIcon {...props} style={{ marginTop: '-5px', blockSize: '1.5rem', inlineSize: '1.5rem', fill: 'currentColor' }} />}
        >
          Edit
        </Button>
      </Link>
    );
  }
}

export { MyEdit };

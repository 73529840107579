// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Datagrid, FunctionField, List, ListBase, ReferenceField, TextField } from 'react-admin';

import { MyBreadcrumbs } from '../component/breadcrumb';

export const IDPList = () => (
  <ListBase>
    <MyBreadcrumbs path={'/idp'} />
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="provider_id" reference="idp/available" link={false}>
          <TextField source="display_name" />
        </ReferenceField>
        <TextField source="endpoint" />
        <FunctionField label="Additional Config" render={(record) => JSON.stringify(record.additional_config)} />
        <FunctionField label="Request Token Extra Arguments" render={(record) => JSON.stringify(record.request_token_extra_arguments)} />
      </Datagrid>
    </List>
  </ListBase>
);

// © Copyright IBM Corp. 2022, 2024

// in App.js
import * as React from 'react';

import { Admin, CustomRoutes, defaultTheme, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { authFactory, fetchJsonWithCookie } from './authProvider';
import { HazPerms } from './component/customComponents';
import { MyLayout } from './component/mainMenu';
import config from './config';
import { Dashboard } from './page/dashboard';
import LoginPage from './page/loginPage';
import { AccessTokenCreate, AccessTokenList, AccessTokenShow } from './resource/accessToken';
import { CACreate, CAEdit, CAFingerprintCreate, CAFingerprintEdit, CAFingerprintList, CAList } from './resource/ca';
import { DomainListCreate, DomainListEdit, DomainListList } from './resource/domainlist';
import { EntityCreate, EntityEdit, EntityList } from './resource/entity';
import { GlobalDomainListList } from './resource/globaldomainlist';
import { HelpPage } from './resource/help';
import { IDPList } from './resource/idp';
import { PolicyChainCreate, PolicyChainList, PolicyCreate, PolicyList } from './resource/policy';
import { ProfileShow } from './resource/profile';
import { ReportList } from './resource/report';
import { ServiceClientCreate, ServiceClientEdit, ServiceClientList, ServiceCreate, ServiceEdit, ServiceList } from './resource/service';
import DnsIcon from '@mui/icons-material/Dns';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import KeyIcon from '@mui/icons-material/Key';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PeopleIcon from '@mui/icons-material/People';
import TrafficIcon from '@mui/icons-material/Traffic';
import VerifiedIcon from '@mui/icons-material/Verified';
import drfProvider from 'ra-data-django-rest-framework-v4';

const dataProvider = drfProvider(`${config.apiUrl}/v1`, fetchJsonWithCookie);

const raptorTheme = {
  ...defaultTheme,
};

const MyAuth = authFactory(`${config.apiUrl}/v1/auth`);

const initialState = {
  admin: { ui: { sidebarOpen: true, viewVersion: 0 } },
};

const App = () => (
  <Admin
    initialState={initialState}
    theme={raptorTheme}
    loginPage={LoginPage}
    authProvider={MyAuth}
    dataProvider={dataProvider}
    requireAuth
    disableTelemetry
    dashboard={Dashboard}
    layout={MyLayout}
  >
    {(permissions) => (
      <>
        <Resource icon={FolderSharedIcon} options={{ label: 'Identity Providers' }} name="idp" list={IDPList} />
        {HazPerms(permissions, 'policies') && (
          <Resource
            icon={DnsIcon}
            options={{ label: 'My Lists' }}
            name="domainlist"
            list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? DomainListList : null}
            edit={HazPerms(permissions, 'policies', 'edit') ? DomainListEdit : null}
            create={HazPerms(permissions, 'policies', 'create') ? DomainListCreate : null}
          />
        )}
        {HazPerms(permissions, 'policies') && (
          <Resource
            icon={DnsIcon}
            options={{ label: 'Global Domain Lists' }}
            name="globaldomainlist"
            list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? GlobalDomainListList : null}
          />
        )}
        {/*<Resource icon={FolderSharedIcon}*/}
        {/*          options={{label: 'Endpoint Providers'}}*/}
        {/*          name="endpointproviders"*/}
        {/*          list={IDPList}*/}
        {/*/>*/}
        <Resource icon={PeopleIcon} options={{ label: 'Entities' }} name="entity" list={EntityList} create={EntityCreate} edit={EntityEdit} />
        {HazPerms(permissions, 'policies') && (
          <Resource
            icon={TrafficIcon}
            options={{ label: 'Policy Chains' }}
            name="policy_chain"
            list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? PolicyChainList : null}
            create={HazPerms(permissions, 'advanced_policies', 'create') ? PolicyChainCreate : null}
          />
        )}
        {HazPerms(permissions, 'policies') && (
          <Resource
            icon={TrafficIcon}
            options={{ label: 'Policies' }}
            name="policy"
            list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? PolicyList : null}
            // show={PolicyShow}
            create={HazPerms(permissions, 'advanced_policies', 'create') ? PolicyCreate : null}
          />
        )}

        {/*<Resource icon={FingerprintIcon}*/}
        {/*          options={{label: 'Classifiers'}}*/}
        {/*          name="classifier"*/}
        {/*          list={IdentityList}*/}
        {/*          show={IdentityList}*/}
        {/*/>*/}
        {/*<Resource icon={RouteIcon}*/}
        {/*          options={{label: 'Actions'}}*/}
        {/*          name="action"*/}
        {/*          list={PolicyList}*/}
        {/*          show={PolicyShow}*/}
        {/*/>*/}
        {/*<Resource icon={DnsIcon}*/}
        {/*          name="endpoint"*/}
        {/*          options={{label: 'Endpoints'}}*/}
        {/*          list={EndpointList}*/}
        {/*          show={EndpointShow}*/}
        {/*/>*/}

        {HazPerms(permissions, 'services') && (
          <Resource
            icon={MultipleStopIcon}
            name="service"
            options={{ label: 'Services' }}
            list={HazPerms(permissions, 'services', 'read') ? ServiceList : null}
            create={HazPerms(permissions, 'services', 'create') ? ServiceCreate : null}
            edit={HazPerms(permissions, 'services', 'edit') ? ServiceEdit : null}
          >
            <Route path=":id/client" element={<ServiceClientList />} />
            <Route path=":id/client/create" element={<ServiceClientCreate />} />
            <Route path=":id/client/:clientId" element={<ServiceClientEdit />} />
            <Route path=":id/fingerprint/available" />
          </Resource>
        )}
        <Resource
          icon={KeyIcon}
          name="accesstoken"
          options={{ label: 'Access Tokens' }}
          list={AccessTokenList}
          create={AccessTokenCreate}
          show={AccessTokenShow}
        />
        <Resource icon={VerifiedIcon} name="ca" options={{ label: 'Certificate Authorities' }} list={CAList} create={CACreate} edit={CAEdit}>
          <Route path=":id/fingerprint" element={<CAFingerprintList />} />
          <Route path=":id/fingerprint/create" element={<CAFingerprintCreate />} />
          <Route path=":id/fingerprint/:fingerprintId" element={<CAFingerprintEdit />} />
        </Resource>
        <Resource options={{ label: 'Logs' }} name="reports/logs" list={ReportList} />
        <CustomRoutes>
          <Route path="/help" element={<HelpPage />} />
          <Route key="profile" path="/profile" element={<ProfileShow />} />
        </CustomRoutes>
      </>
    )}
  </Admin>
);

export default App;

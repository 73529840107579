// © Copyright IBM Corp. 2022, 2024

// in src/MyLoginPage.js
import * as React from 'react';

import { Login } from 'react-admin';

import { Box, CardMedia, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import GitHubIcon from '@mui/icons-material/GitHub';
import SecurityIcon from '@mui/icons-material/Security';
import { Avatar, ListItemButton } from '@mui/material';
import base32Encode from 'base32-encode';

import config from '../../config';

class AuthProviderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      org: this.props.org,
      token: null,
    };
  }

  isValidUrl = (urlString) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (e) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  logoUrlToLogo(url) {
    let targetUrl = '';
    if (this.isValidUrl(url)) {
      targetUrl = url;
    }
    switch (url) {
      // real url was supplied and its valid
      case targetUrl:
        return <Avatar style={{ border: 0, objectFit: 'cover' }} src={url} variant={'square'} />;
      case 'github':
        return <GitHubIcon />;
      default:
        return <SecurityIcon />;
    }
  }

  generate_token = async () => {
    let c = window.crypto.subtle;
    // local dev doesn't have crypto
    if (!c) {
      c = {
        generateKey: async () => {
          return 'localdev';
        },
        exportKey: async () => {
          var enc = new TextEncoder();
          let makerand = () => {
            return (Math.random() + 1).toString(36).substring(7);
          };
          return Promise.resolve(enc.encode(`${makerand()}${makerand()}`));
        },
      };
    }
    let toReturn = await c
      .generateKey(
        {
          name: 'HMAC',
          hash: { name: 'SHA-256' },
        },
        true,
        ['sign', 'verify'],
      )
      .then((key) => {
        return c.exportKey('raw', key).then((extracted) => {
          let url = base32Encode(extracted, 'RFC4648', { padding: false });
          return `${url.slice(0, url.length / 2)}.${url.slice(url.length / 2, url.length)}`;
        });
      });
    this.setState({ token: toReturn });
    return toReturn;
  };

  bind_dns = async (token) => {
    await fetch(`//${token}.b.ns1routing.net/`)
      .then((r) => r)
      .catch(() => {});
  };

  get_providers = async (token) => {
    // put retry logic here
    let delay = (ms) => new Promise((res) => setTimeout(res, ms));
    await delay(500);

    let providers = await fetch(`${config.apiUrl}${config.clientBasePath}/begin/${encodeURIComponent(token)}/`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status === 404) {
        throw { client: 'No registered providers' };
      }
      return res.json();
    });
    return providers;
  };

  resolve_login = async () => {
    let token = this.state.token;
    if (token === null) {
      this.setState({ token: '' });
      token = await this.generate_token();
      this.setState({ token: token });
    }
    this.get_providers(token).then(
      (result) => {
        this.setState({
          isLoaded: true,
          auth_providers: result.auth_providers,
          org_icon: result.org_icon || 'default_org_icon',
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
        this.setState({
          isLoaded: false,
          error,
        });
      },
    );
  };

  componentDidMount() {
    this.resolve_login();
  }

  render() {
    const { error, isLoaded, auth_providers, org_icon } = this.state;
    if (error) {
      return <div>Error: {error.client}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Box style={{ display: 'block', width: '100%', maxWidth: 360 }}>
          <CardMedia component={'img'} style={{ maxWidth: 150, margin: 'auto' }} src={org_icon} />
          <List>
            {auth_providers.map((provider) => (
              <ListItem key={provider.url}>
                <ListItemButton
                  // TODO: use the same style as react admin or change landing page
                  style={{ background: '#1976d2', borderRadius: 4 }}
                  onClick={() => (window.location.href = `${provider.url}?next=${window.location.origin}/`)}
                >
                  <ListItemIcon style={{ color: 'black' }}>{this.logoUrlToLogo(provider.icon)}</ListItemIcon>
                  <ListItemText primary={<Typography>Sign in with {provider.display_name}</Typography>} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      );
    }
  }
}

const LoginPage = () => {
  return (
    <Login
    //backgroundImage="https://source.unsplash.com/random/1600x900/daily"
    >
      <AuthProviderList />
    </Login>
  );
};

export default LoginPage;

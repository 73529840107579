// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  ChipField,
  Create,
  CreateBase,
  Datagrid,
  DateField,
  Edit,
  EditBase,
  EditButton,
  List,
  ListBase,
  ReferenceArrayField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  useGetList,
  useGetRecordId,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionButton, CustomToolbar, HazPerms } from '../component/customComponents';
import JsonEditor from '../component/jsonEditor';
import { copyToClipboard, MyTooltip } from '../utils/utils';

export const ServiceCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/service');
    refresh();
    notify(`Service created`, { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/service'} />
      <Create {...props} mutationOptions={{ onSuccess }}>
        <ServiceForm
          {...props}
          redirect="list"
          defaultValues={{
            is_active: true,
            name: '',
            description: '',
          }}
        />
      </Create>
    </CreateBase>
  );
};

export const ServiceForm = ({ edit, ...props }) => {
  const optionRenderer = (choice) => `${choice.name}`; // needed because react-admin adds a leading #
  return (
    <SimpleForm {...props} toolbar={<CustomToolbar redirectPath={'/service'} showDelete={true} />}>
      {/*<TextField source="id"/>*/}
      <TextInput fullWidth source="name" />
      <TextInput fullWidth source="description" />
      <BooleanInput source="is_active" />
      <div style={{ width: '100%' }}>
        <ReferenceInput source="policy_chain" reference="policy_chain">
          <SelectInput style={{ width: 'auto' }} optionText={optionRenderer} label="Policy Chain" />
        </ReferenceInput>
      </div>
      <JsonEditor source="meta" />
      {edit && <ClientShowButton />}
    </SimpleForm>
  );
};

export const ServiceEdit = (props) => (
  <EditBase>
    <MyBreadcrumbs path={'/service'} />
    <Edit {...props} mutationMode="pessimistic">
      <ServiceForm edit={true} />
    </Edit>
  </EditBase>
);

const ClientShowButton = () => {
  const recordId = useGetRecordId();
  const redirect = useRedirect();
  const handleClick = (event) => {
    redirect(`/service/${recordId}/client/`);
    event.stopPropagation();
  };
  return (
    <Button onClick={handleClick} label="Show Clients">
      <DevicesOtherIcon />
    </Button>
  );
};

export const ServiceList = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <ListBase>
      <MyBreadcrumbs path={'/service'} />

      <List sort={{ field: 'name' }}>
        <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButton />}>
          <TextField source="id" />
          <TextField source="name" />
          <BooleanField source="is_active" />
          <ReferenceArrayField fullWidth label="Certificate Authorities" reference="ca" source="client_cas">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <DateField label="Date Created" source="created_at" type="date" />
          <ClientShowButton label="Clients" />
          {HazPerms(permissions, 'services', 'edit') ? <EditButton label="Edit" /> : null}
        </Datagrid>
      </List>
    </ListBase>
  );
};

const CAClientAutocomplete = () => {
  const { id } = useParams();
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList(
    `service/${id}/fingerprint/available`,
    {
      filter: {},
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
    },
    {
      onSuccess: (r) => {
        console.log('fingerprints:', r);
      },
      onError: (e) => {
        console.log(e);
      },
    },
  );

  const includeCurrent = (available, current) => {
    let values;
    if (current?.certificate_fingerprint) {
      values = { id: current.certificate_fingerprint, name: current.certificate_fingerprint };
      available.push(values);
    }
    return available;
  }; //adds all the current fingerprints to all the available fingerprints

  if (error) {
    return <p>ERROR</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }
  console.log(record);

  return (
    <AutocompleteInput
      isLoading={isLoading}
      optionText="name"
      optionValue="id"
      source="certificate_fingerprint"
      choices={data ? includeCurrent(data, record).map((item) => ({ id: item.id, name: item.name })) : []}
      emptyText="----"
      style={{ width: '50%' }}
    />
  );
};

export const ServiceClientForm = ({ edit, ...props }) => {
  const notify = useNotify();
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(event.target.textContent) ? notify('text copied to clipboard', { type: 'success' }) : notify('failed to copy text', { type: 'error' });
  };

  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      {edit && <BooleanField source="bound" />}
      <BooleanInput source="allow_dynamic_binding" />
      <BooleanInput defaultValue={true} source="enable_doh_listener" />
      <BooleanInput defaultValue={true} source="enable_dot_listener" />
      <ArrayField source="listeners" label="Listeners">
        <Datagrid bulkActionButtons={false} rowClick={() => {}}>
          <TextField source={'protocol'} />
          {/*<Stack direction="row" spacing={2}>*/}
          <MyTooltip title="click to copy" aria-label="click to copy">
            <TextField source={'url'} onClick={handleClick} style={{ wordBreak: 'break-all' }} />
          </MyTooltip>
          {/*<ContentPasteIcon/>*/}
          {/*</Stack>*/}
          <MyTooltip title="click to copy" aria-label="add">
            <TextField source={'token'} onClick={handleClick} style={{ wordBreak: 'break-all' }} />
          </MyTooltip>
          {/*<ContentPasteIcon/>*/}
        </Datagrid>
      </ArrayField>
      <JsonEditor source="meta" />
      <CAClientAutocomplete />
    </SimpleForm>
  );
};

export const ServiceClientList = () => {
  const notify = useNotify();

  const { id } = useParams();
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(event.target.textContent) ? notify('text copied to clipboard', { type: 'success' }) : notify('failed to copy text', { type: 'error' });
  };
  return (
    <ListBase>
      <MyBreadcrumbs path={`/service/${id}/client`} />
      <List resource={`service/${id}/client`} hasCreate={true}>
        <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButton />}>
          <TextField source="id" />
          <TextField source="name" />
          <ArrayField source="listeners" label="Listeners">
            <Datagrid bulkActionButtons={false} onClick={handleClick} rowClick={() => {}}>
              <TextField onClick={handleClick} source={'protocol'} />
              <MyTooltip title="click to copy" aria-label="add">
                <TextField onClick={handleClick} source={'url'} style={{ wordBreak: 'break-all' }} />
              </MyTooltip>
              <MyTooltip title="click to copy" aria-label="add">
                <TextField onClick={handleClick} source={'token'} style={{ wordBreak: 'break-all' }} />
              </MyTooltip>
            </Datagrid>
          </ArrayField>
          {/*<ReferenceArrayField fullWidth label="Certificate Authorities" reference="ca" source="client_cas">*/}
          {/*    <SingleFieldList>*/}
          {/*        <ChipField source="name"/>*/}
          {/*    </SingleFieldList>*/}
          {/*</ReferenceArrayField>*/}
          <DateField label="Date Created" source="created_at" type="date" />
        </Datagrid>
      </List>
    </ListBase>
  );
};

export const ServiceClientCreate = () => {
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect(`/service/${id}/client`);
    refresh();
    notify(`Service client created`, { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={`/service/${id}/client`} />
      <Create resource={`service/${id}/client`} mutationOptions={{ onSuccess }}>
        <ServiceClientForm toolbar={<CustomToolbar redirectPath={`/service/${id}/client`} />}></ServiceClientForm>
      </Create>
    </CreateBase>
  );
};

export const ServiceClientEdit = () => {
  const { id, clientId } = useParams();
  return (
    <EditBase>
      <MyBreadcrumbs record={clientId} path={`/service/${id}/client`} />
      <Edit resource={`service/${id}/client`} id={clientId} mutationMode="pessimistic">
        <ServiceClientForm edit={true} toolbar={<CustomToolbar redirectPath={`/service/${id}/client`} showDelete={true} />}></ServiceClientForm>
      </Edit>
    </EditBase>
  );
};

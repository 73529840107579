// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

// import Button from '@mui/material/Button';
import { BulkDeleteButton, DeleteButton, SaveButton, Toolbar, useRedirect, useRefresh } from 'react-admin';

import { Button } from '@carbon/react';

const ButtonStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: '0',
  // backgroundColor: 'var(--cds-button-danger-primary, #da1e28)',
  color: 'var(--cds-text-on-color, #ffffff)',
  padding: '0',
  boxSizing: 'border-box',
  border: '0',
  margin: 0,
  fontFamily: 'inherit',
  fontSize: '100%',
  verticalAlign: 'baseline',
  fontWeight: 'var(--cds-body-compact-01-font-weight, 400)',
  lineHeight: 'var(--cds-body-compact-01-line-height, 1.28572)',
  letterSpacing: 'var(--cds-body-compact-01-letter-spacing, 0.16px)',
  position: 'relative',
  display: 'inline-flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  cursor: 'pointer',
  inlineSize: 'max-content',
  maxInlineSize: '20rem',
  minBlockSize: 'var(--cds-layout-size-height-local)',
  outline: 'none',
  paddingBlock: 'min((var(--cds-layout-size-height-local) - var(--temp-1lh)) / 2 - 0.0625rem, var(--temp-padding-block-max))',
  paddingInlineEnd: 'calc(var(--cds-layout-density-padding-inline-local) * 3 + 1rem - 0.0625rem)',
  paddingInlineStart: 'calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem)',
  textAlign: 'start',
  textDecoration: 'none',
  transition:
    ' background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9)',
  textTransform: 'initial',
};

const CancelButton = ({ redirectPath }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleClick = () => {
    redirect('show', redirectPath);
    refresh();
  };

  return (
    <Button kind="secondary" color="primary" onClick={handleClick}>
      Cancel
    </Button>
  );
};

export const CustomToolbar = ({ redirectPath, showDelete = true }) => {
  return (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <SaveButton label="Save" className="cds--btn cds--btn--primary" style={{ ...ButtonStyle }} />
      <CancelButton redirectPath={redirectPath} />
      {showDelete && (
        <DeleteButton
          label="Delete"
          className="cds--btn cds--btn--danger"
          style={{ ...ButtonStyle, backgroundColor: 'var(--cds-button-danger-primary, #da1e28)' }}
        />
      )}
    </Toolbar>
  );
};

export const BulkActionButton = (props) => (
  <React.Fragment>
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} mutationMode="pessimistic" />
  </React.Fragment>
);

// TODO: make this an API call
export const HazPerms = (permissions, resource, action) => {
  var canAccess = false;

  // resource should be set, either to something specific like policies
  // or to wildcard '*'
  if (!resource) {
    return canAccess;
  }

  // if resource is defined but not action, then the check is to see if there are
  // any settings set
  if (!action) {
    if (permissions.filter((p) => p.resource === resource).length) {
      canAccess = true;
    }
  }

  // check for wildcards
  // return true if the resource and action are *
  if (permissions.filter((p) => p.resource === '*')?.filter((p) => p.actions.includes('*')).length) {
    canAccess = true;
  }

  // check if resource is wildcard and specific action is defined
  else if (permissions.filter((p) => p.resource === '*')?.filter((p) => p.actions.includes(action)).length) {
    canAccess = true;
  }

  // check if the requested resource has wildcard for actions
  else if (permissions.filter((p) => p.resource === resource)?.filter((p) => p.actions.includes('*')).length) {
    canAccess = true;
  }

  // check for specific match on resource and action
  else if (permissions.filter((p) => p.resource === resource)?.filter((p) => p.actions.includes(action)).length) {
    canAccess = true;
  }
  return canAccess;
};

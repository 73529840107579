// © Copyright IBM Corp. 2022, 2024

// in src/users.js
import * as React from 'react';
import { useState } from 'react';

import {
  Button,
  Create,
  CreateBase,
  Datagrid,
  DateField,
  Form,
  List,
  ListBase,
  PasswordInput,
  ReferenceField,
  required,
  SaveButton,
  Show,
  ShowBase,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  useUnique,
} from 'react-admin';

import IconCancel from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionButton } from '../component/customComponents';

export const AccessTokenList = () => (
  <ListBase>
    <MyBreadcrumbs path={'/accesstoken'} />
    <List>
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<BulkActionButton />}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="created_by" reference="user">
          <TextField source="username" />
        </ReferenceField>
        <DateField label="Date Created" source="date_created" type="date" />
      </Datagrid>
    </List>
  </ListBase>
);

export const AccessTokenShow = () => (
  <ShowBase>
    <MyBreadcrumbs path={'/accesstoken'} />
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="created_by" reference="user">
          <TextField source="username" />
        </ReferenceField>
        <DateField source="date_created" />
      </SimpleShowLayout>
    </Show>
  </ShowBase>
);

export const AccessTokenCreate = () => {
  const unique = useUnique();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [token, setToken] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleCloseClick = () => {
    redirect('/accesstoken');
  };

  const preventCloseClick = () => {
    notify(`Please use the close button. To close this dialog`, {
      type: 'warning',
    });
  };

  const onCreateSuccess = (data) => {
    setToken(data['token']);
    setShowDialog(true);
    notify('ra.notification.created', {
      type: 'info',
      messageArgs: { smart_count: 1 },
    });
    redirect(false);
  };

  const handleCancel = () => {
    redirect('show', '/accesstoken');
    refresh();
  };

  const handleCopy = () => {
    document.getElementById('htoken').select();
    document.execCommand('copy');
    notify('Token copied to clipboard', { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/accesstoken'} />
      <Create>
        <>
          <SimpleForm
            toolbar={
              <Toolbar sx={{ justifyContent: 'space-between' }}>
                <SaveButton
                  label="Create New Token"
                  mutationOptions={{
                    onSuccess: onCreateSuccess,
                  }}
                  type="button"
                />
                <Button color="primary" onClick={handleCancel} label="Cancel" />
              </Toolbar>
            }
          >
            <TextInput source="name" validate={[required(), unique()]} fullWidth />
          </SimpleForm>
          <Dialog fullWidth open={showDialog} onClose={preventCloseClick} aria-label="Token Dialog">
            <DialogActions
              sx={{
                display: 'inline-block',
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                <DialogTitle>One Time Token Dialog</DialogTitle>
                <Button alignIcon="right" color="inherit" size="large" label="Close" onClick={handleCloseClick}>
                  <IconCancel />
                </Button>
              </Stack>
            </DialogActions>
            <DialogContent>
              <Typography variant="h8">Please copy your token. After closing this dialog it will not be presented again.</Typography>
              <Form record={{ token: token }} toolbar={false}>
                <PasswordInput source="token" onClick={(event) => event.target.type === 'text' && event.target.select()} sx={{ width: '83%' }}></PasswordInput>
                <Button color="inherit" label="Copy" onClick={handleCopy} sx={{ float: 'right', marginTop: '10px' }} size="large">
                  <ContentCopyIcon />
                </Button>
                <TextInput source="token" sx={{ position: 'absolute', left: '-1000px', top: '-1000px' }} id="htoken" />
              </Form>
            </DialogContent>
          </Dialog>
        </>
      </Create>
    </CreateBase>
  );
};

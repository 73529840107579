// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  BooleanInput,
  Button,
  ChipField,
  Create,
  CreateBase,
  Datagrid,
  Edit,
  EditBase,
  EditButton,
  List,
  ListBase,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  useGetRecordId,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import FingerprintIcon from '@mui/icons-material/Fingerprint';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionButton, CustomToolbar } from '../component/customComponents';
import JsonEditor from '../component/jsonEditor';

export const CAForm = ({ edit, ...props }) => (
  <SimpleForm {...props} toolbar={<CustomToolbar redirectPath={'/ca'} />}>
    <TextInput fullWidth source="name" required={true} />
    <TextInput fullWidth source="description" />
    <TextInput fullWidth multiline inputProps={{ style: { fontFamily: 'Monospace' } }} source="cert" required={false} />
    <BooleanInput fullWidth source="public" />
    <ReferenceArrayInput fullWidth label="Services" reference="service" source="services">
      <SelectArrayInput source="name" />
    </ReferenceArrayInput>
    <JsonEditor source="meta" />
    {edit && <FingerprintShowButton />}
  </SimpleForm>
);

export const CACreate = ({ props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/ca');
    refresh();
    notify('Created', { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path="/ca" />
      <Create {...props} mutationOptions={{ onSuccess }}>
        <CAForm
          defaultValues={{
            cert: '',
            name: '',
            description: '',
            services: [],
            public: false,
          }}
        />
      </Create>
    </CreateBase>
  );
};

export const CAEdit = ({ props }) => (
  <EditBase>
    <MyBreadcrumbs path="/ca" />
    <Edit {...props} mutationMode="pessimistic">
      <CAForm edit={true} {...props} />
    </Edit>
  </EditBase>
);
export const CAList = () => (
  <ListBase>
    <MyBreadcrumbs path={'/ca'} />
    <List>
      <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButton />}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField fullWidth label="Services" reference="service" source="services">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FingerprintShowButton label="Fingerprints" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  </ListBase>
);

export const CAFingerprintForm = (props) => (
  <SimpleForm {...props} toolbar={<CustomToolbar redirectPath={props.path} />}>
    <TextInput source="name"></TextInput>
    <TextInput source="description"></TextInput>
    <TextInput source="fingerprint" fullWidth={true}></TextInput>
  </SimpleForm>
);
export const CAFingerprintList = () => {
  const { id } = useParams();
  return (
    <ListBase>
      <MyBreadcrumbs path={`/ca/${id}/fingerprint`} />
      <List resource={`ca/${id}/fingerprint`} hasCreate={true}>
        <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButton />}>
          <TextField source="id" />
          <TextField source="name"></TextField>
          <TextField source="description"></TextField>
          <TextField source="fingerprint"></TextField>
        </Datagrid>
      </List>
    </ListBase>
  );
};
export const CAFingerprintEdit = () => {
  const { id, fingerprintId } = useParams();
  return (
    <EditBase>
      <MyBreadcrumbs path={`/ca/${id}/fingerprint`} />
      <Edit resource={`ca/${id}/fingerprint`} id={fingerprintId} mutationMode="pessimistic">
        <CAFingerprintForm path={`/ca/${id}/fingerprint`} />
      </Edit>
    </EditBase>
  );
};
export const CAFingerprintCreate = () => {
  const { id } = useParams();
  return (
    <CreateBase>
      <MyBreadcrumbs path={`/ca/${id}/fingerprint`} />
      <Create resource={`ca/${id}/fingerprint`}>
        <CAFingerprintForm path={`/ca/${id}/fingerprint`} />
      </Create>
    </CreateBase>
  );
};

const FingerprintShowButton = () => {
  const recordId = useGetRecordId();
  const redirect = useRedirect();
  const handleClick = (event) => {
    redirect(`/ca/${recordId}/fingerprint/`);
    event.stopPropagation();
  };
  return (
    <Button onClick={handleClick} label={'Show Fingerprints'}>
      <FingerprintIcon />
    </Button>
  );
};
